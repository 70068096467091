.runScriptButton{
    align-self: center;
}

.runScriptDiv{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.consoleContent{
    white-space: pre-wrap;
    margin: 10px;
    overflow-y: auto;
    max-height: 50vh;
}

.runScriptContainer{
    margin: 15px 30px 0 30px;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
}
