.btn-send-djs ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    justify-content: center;
}

ul.pagination li {
    width: auto;
    padding: 4px 0px;
    font-size: 16px;
    font-weight: bold;
    color: #000 !important;
    border-radius: 5px;
    display: flex;
}
ul.pagination li.active {
    color: #fff !important;
    /*background: #34bfa3;*/
    cursor: pointer;
}
ul.pagination li.active a {
    color: #fff !important;
}
ul.pagination li.previous{
    /*font-size: 0px;*/
}
ul.pagination li a{
    color:#000;
    cursor: pointer;
    text-decoration: none;
}

.paginate {
  column-gap: 0px;
}

.right-contentarea .btn-send-djs ul li a {
    border-radius: 0;
    padding: 7px 10px;
}
ul.pagination li.previous a, ul.pagination li.next a {
    border: solid #2aa19c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    cursor: pointer;
}
ul.pagination li.previous a {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
ul.pagination li.next a {
    transform: rotate(-45deg); -webkit-transform: rotate(-45deg);
    
}
.right-contentarea .btn-send-djs ul li a:hover {
    color: #fff;
}
ul.pagination li.previous a:hover, ul.pagination li.next a:hover {
    background: none;
    text-decoration: none;
    text-transform: none;
}
.pagination li.next.disabled,
.pagination li.previous.disabled {
    display: none;
}

.date-sec-wrap{
    display: flex;
    align-items: center;
}
.reset-search-btn{
    margin-left: 10px;

}
.reset-search-btn .reset-btn{
    margin-left: 10px;
}

@media (max-width:767px){
    div.date-sec-wrap{
        display: block;
    }
    div.date-sec-wrap .date-input-field-wrap{
        margin-bottom: 10px;
    }   
    div.date-sec-wrap .reset-search-btn{
        margin-left: 0px;
    }
     .datePicerSect div.date-sec-wrap .DayPickerInput input{
        width: 150px;
    }

}