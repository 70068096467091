// .left-column {
//     display: flex;
// }

// .right-column {
//     display: flex;
// }

.custom-modal-style {
    max-width: 800px;
}
