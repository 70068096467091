body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrongField, .wrongField input, .wrongField select {
    border-color: #ff0d01 !important;
}

.curatedSetTracksDialog > div {
    max-width: 90vw !important;
}

.baseDialogContent{
    max-height: 98vh;
}

.baseDialogBody{
    overflow: auto;
}
div.InputFromTo.error > .DayPickerInput >  input,
span.InputFromTo-to.error .DayPickerInput  input {
  border-color: red;
}

.datePicerSect{
  margin-bottom: 20px;
}

.datePicerSect .DayPickerInput input{
  border-radius: 22px;
  border: 1px solid #ccc;
  padding: 7px 15px;
  width: 200px;
}

.datePicerSect label{
  font-weight: bold;
  font-size: 16px;
}

ul.list-inline.actionsUl li > a:hover svg {
  fill: #259d85;
  cursor: pointer;
}

button.close-button {
  background: aliceblue;
  border: 1px solid #42ffd9;
  margin: 5px;
  border-radius: 36px;
  padding: 2px 7px 4px 10px;
  position: relative;
}

span.close-icon {
  position: absolute;
  top: -5px;
  right: -4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: red;
  background: #ffffff;
  width: 12px;
  height: 13px;
  padding: 0;
  display: block;
  line-height: 0.55;
  border-radius: 15px;
  z-index: 999;
  border: 1px solid #fbb1b1;
}

.banner-form {
  background: #ebfffb;
}

.m-portlet .track-list {
  background: #d4e5e2;
}

.m-portlet {
  display: flow-root;
}

.loading {
  height: 135vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  /* filter: blur(7px); */
  background: #ffffffab;
}

.loader {
  width: 158px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 9;
  text-align: center;
  top: 40vh;
}

.css-bh4wfl-wrapper-Loader {
  margin: auto;
}

span.loading-text {
  font-size: large;
  font-weight: 600;
  letter-spacing: 1px;
}

.bannerList{
  position: relative;
}
.bannerList td {
  height: calc(160px* 300 / 700);
  overflow: hidden;
  position: relative;
}
.bannerList td img {
  width: auto;
  height: auto;
  border-radius: 5px;
  max-height: 63px;
  max-width: fit-content;
}
.loader.message {
  width: auto;
}
.loader.message span {
  font-size: 20px;
  font-family: sans-serif;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.loader.message span.green-text {
  color: green;
}
.loader.message span.red-text {
  color: red;
}

.popupBannerWrap.banner-order-change .flexAlignment input{
  width: auto;
}
.popupBannerWrap.banner-order-change .flexAlignment{
  max-width: 400px;
}
.popupBannerWrap.banner-order-change .flexAlignment .checkBox{
  margin: 0px 0px 0px 10px;
  cursor: pointer;
}

.popupBannerWrap.banner-order-change .flexAlignment{
  padding: 40px 40px;
}

.popupBannerWrap.banner-order-change .flexAlignment .control-label{
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.popupBannerWrap.banner-order-change .flexAlignment  .btnGap{
  gap: 20px;
  display: flex;
}

.popupBannerWrap.banner-order-change .flexAlignment  .fontNormal{
  font-size: 15px;
}

.banner-order-change .flexAlignment input {
  margin:20px 8px;
  padding: 13px 18px;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 100px;
  font-size: 15px;
  margin-bottom: 28px;
}
span.loading-text.banner-reupload-msg {
  white-space: nowrap;
  font-size: 22px;
  color: green;
  font-weight: 600;
}
.popup-container.banner-order-change {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
}
.banner-order-change .flexAlignment {
  position: relative;
  margin-bottom: 24px;
  gap: 16px;
  text-align:center;
  background: #fff;
  padding: 40px 60px;
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.banner-order-change .flexAlignment .btn{
  border-radius: 100px;
  padding: 13px 28px;
  font-size: 15px;
}

.banner-order-change .closeIcon i {
  font-size: 19px;
  cursor: pointer;
  opacity: .6;
  transition:all .5s ease;
}
.banner-order-change .closeIcon i:hover{
  opacity: 1;
}
.banner-order-change .closeIcon {
  position: absolute;
  top: 16px;
  right: 20px;
}
.banner-re-upload .flexAlignment input{
  margin: 0px 0px 20px 0px ! important;
}
.label_Inline label {
  text-align: left;
  width: auto;
  flex: unset;
}

.label_Inline {
  justify-content: left;
  margin: 0 !important;
}

.label_Inline input {
  width: 100% !important;
  max-width: 100% !important;
  flex: unset !important;
}