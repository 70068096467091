.text-field-label {
  width: 10px;
}

.left-panel {
  width: 47%;
  margin-right: 3%;
}

.right-panel {
  width: 47%;
  margin-right: 3%;
}

.loading-indicator {
  display: flex;
  justify-content: center;
}